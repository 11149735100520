import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Carousel"], {
    ref: "carousel",
    autoplay: 0,
    "wrap-around": false,
    "snap-align": 'start',
    mouseDrag: true,
    breakpoints: {
          480: {
            itemsToShow: 2,
          },
          768: {
            itemsToShow: 3,
          },
          1024: {
            itemsToShow: 3,
          },
          1336: {
            itemsToShow: 4,
          },
        }
  }, {
    addons: _withCtx(() => [
      ($setup.showNavigation)
        ? (_openBlock(), _createBlock($setup["Navigation"], { key: 0 }))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.productList, (product) => {
        return (_openBlock(), _createBlock($setup["Slide"], {
          key: product.Id
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ProductCard"], {
              "product-info": product,
              size: "medium",
              "add-to-cart": $props.addToCart,
              "path-prefix": $setup.pathPrefix(product)
            }, null, 8 /* PROPS */, ["product-info", "add-to-cart", "path-prefix"])
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}